import React from "react";
import styles from "../../styles/components/base/Footer.module.css";

const Footer = () => {
    return (
        <footer id={styles.footer}>
            <div className="container">
                   <section id={styles.footer_bottom}>
                    <p className={styles.copyright}>© 2022  - All Rights Reserved</p>
                </section>
            </div>
        </footer>
    )
}

export default Footer;
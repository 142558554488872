import React, {useRef} from "react";
import {generateClasses} from "../../scripts/Helpers";
import styles from "../../styles/components/utils/ProjectItem.module.css";

const ProjectItem = ({classNames, title, caption, url, thumbnailProperties, ariaLabel, visible=true, minify=false, disabled=false}) => {

    const projectRef = useRef();

    /**
     * Renders a project depending on whether it should be visible
     * @returns {JSX.Element|null} The project if visible; or null.
     */
    const renderProject = () => {
        if (visible)
        {
            return <a ref={projectRef}
                      href={url}
                      target="_self"
                      className={generateClasses(classNames, styles.project)}
                      aria-label={ariaLabel}
            >
                <figure className={styles.project_thumbnail}>
                    <img src={thumbnailProperties["src"]}
                         alt={thumbnailProperties["alt"]}
                         srcSet={thumbnailProperties["srcSet"]}
                         width={thumbnailProperties["width"]}
                         height={thumbnailProperties["height"]}
                         decoding="async"
                    />
                </figure>

                <div className={styles.project_content}>
                    <p>
                        <span className={styles.project_content_title}>{title}</span> — {caption}
                    </p>
                </div>
            </a>
        }

        return null;
    }

    return (
        renderProject()
    )
}

export default ProjectItem;
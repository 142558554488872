import React from "react";
import {Helmet} from "react-helmet";
import Navigation from "../base/Navigation";
import styles from "../../styles/components/pages/About.module.css";
import Footer from "../base/Footer";
import ProjectCollection from "../utils/ProjectCollection";
import doubleDiamond410 from "../../resources/images/Double-Diamond-410x236.png";
import doubleDiamond936 from "../../resources/images/Double-Diamond-936x540.png";
import doubleDiamond1200 from "../../resources/images/Double-Diamond-1200x692.png";
import doubleDiamond1312 from "../../resources/images/Double-Diamond-1312-757.png";

const About = () => {
    return (
        <>
            <Helmet>
                <title>About - Asel's Product Design Portfolio</title>
                <meta
                    name="description"
                    content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"
                />

                {/*Open Graph*/}
                <meta property="og:title" content="Asel | Product Designer" />
                <meta property="og:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!" />
                <meta property="og:url" content="https://asathkumara.com/" />
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:alt" content="A thumbnail featuring my signature" />

                {/*Twitter*/}
                <meta name="twitter:title" content="Asel | Product Designer"/>
                <meta name="twitter:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"/>
                <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta name="twitter:image:alt" content="A thumbnail featuring my signature"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <Navigation/>
            <main>
                <section id={styles.hero}>
                    <div className="container">
                        <p id={styles.caption}>About</p>
                        <h1 id={styles.hero_header}>I enjoy <span className="highlight_purple">solving</span> complex problems.</h1>
                    </div>
                </section>

                <section id={styles.content}>
                        <section id={styles.about}>
                            <div id={styles.content_container} className="container">
                                <section id={styles.design_journey}>
                                    <h2>Why Design?</h2>
                                    <p>I&rsquo;ve always had a penchant for problem-solving. It was this innate curiosity that initially drove me to teach myself coding and embark on a career as a Software Engineer. However, it was during my time as an undergraduate student, where I found immense fulfillment in helping others through tutoring. While debugging code was satisfying, I discovered a deeper passion for debugging and improving people&rsquo;s experiences. Witnessing the transformation and impact on my students brought me great joy, igniting a desire to make a broader positive influence.</p>
                                    <p>Recognizing the power to combine my technical expertise with creativity, I saw an opportunity to shape meaningful products and craft exceptional experiences that truly enhance people&rsquo;s lives!</p>
                                </section>

                                <section id={styles.design_philosophy}>
                                    <h2>Design Philosophy</h2>
                                    <p>At the core of my design philosophy is a commitment to user-centricity. I believe that every design challenge should revolve around the needs, and experiences of the end-users. This philosophy is anchored by the Double Diamond Process, a framework that guides my work.</p>
                                    <figure className={styles.image_wrapper}>
                                        <img src={doubleDiamond1312}
                                             alt="An image depicting the Double Diamond design process."
                                             srcSet={
                                                 `${doubleDiamond1312} 1312w,
                                                  ${doubleDiamond1200} 1200w,
                                                  ${doubleDiamond936} 936w,
                                                  ${doubleDiamond410} 410w,`
                                             }
                                             width="1312"
                                             height="7571"
                                             decoding="async"
                                             loading="lazy"
                                        />
                                    </figure>
                                </section>
                            </div>
                        </section>
                </section>
            </main>

            <Footer/>
        </>
    )
}

export default About;
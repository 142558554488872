import React from "react";
import styles from "../../styles/components/utils/ServiceCollection.module.css";
import {generateClasses} from "../../scripts/Helpers";
import ServiceItem from "./ServiceItem";

const ServiceCollection = ({classNames, filter=null}) => {

    return (
        <section className={generateClasses(classNames, styles.service_collection)}>
            <ServiceItem title="Web Design & Development"
                         caption="Drive engagement, leads, and sales with meaningful website experiences across all devices."
                         url="#"
                         ariaLabel="Learn more about the web design & development service I provide."
                         visible={filter == null ? true : filter["Web Design & Development"]}
            />
            <ServiceItem title="Accessibility Auditing"
                         caption="Ensure compliance with WCAG 2.1 guidelines and make your website experiences accessible to all users."
                         url="#"
                         ariaLabel="Learn more about the accessibility auditing service I provide."
                         visible={filter == null ? true : filter["Heuristic Evaluation"]}
            />
            <ServiceItem title="Heuristic Evaluation"
                         caption="Enhance user experiences and optimize usability by identifying usability issues, and streamlining user flows."
                         url="#"
                         ariaLabel="Learn more about the heuristic evaluation service I provide."
                         visible={filter == null ? true : filter["Heuristic Evaluation"]}
            />
            <ServiceItem title="Search Engine Optimization"
                         caption="Appear in top search results and boost traffic by increasing visibility and building trust."
                         url="#"
                         ariaLabel="Learn more about the search engine optimization service I provide."
                         visible={filter == null ? true : filter["Search Engine Optimization"]}
            />
        </section>
    );
};

export default ServiceCollection;
import ProjectItem from "./ProjectItem";
import React from "react";
import styles from "../../styles/components/utils/ProjectCollection.module.css";
import {generateClasses} from "../../scripts/Helpers";

import ncrpThumbnail451 from "../../resources/images/projects/ncrp/images/thumbnails/Thumbnail-451x300.png";
import ncrpThumbnail735 from "../../resources/images/projects/ncrp/images/thumbnails/Thumbnail-735x488.png";
import ncrpThumbnail1200 from "../../resources/images/projects/ncrp/images/thumbnails/Thumbnail-1200x797.png";
import medisafeThumbnail451 from "../../resources/images/projects/medisafe/images/thumbnails/Thumbnail-451x300.png";
import medisafeThumbnail735 from "../../resources/images/projects/medisafe/images/thumbnails/Thumbnail-735x488.png";
import medisafeThumbnail1200 from "../../resources/images/projects/medisafe/images/thumbnails/Thumbnail-1200x797.png";
import archaeophyteThumbnail451 from "../../resources/images/projects/archaeophyte/images/thumbnails/Thumbnail-451x300.png";
import archaeophyteThumbnail735 from "../../resources/images/projects/archaeophyte/images/thumbnails/Thumbnail-735x488.png";
import archaeophyteThumbnail1200 from "../../resources/images/projects/archaeophyte/images/thumbnails/Thumbnail-1200x797.png";

const ProjectCollection = ({classNames, filter=null, minify=false}) => {

    return (
        <section className={generateClasses(classNames, styles.project_collection)}>
                <ProjectItem title="Medisafe"
                             caption="Redesigning Medisafe‘s Apple Watch App for an enhanced user experience."
                             url="/project/medisafe"
                             thumbnailProperties={{
                                 "src": medisafeThumbnail451,
                                 "alt": "Patient wearing an Apple Watch with the redesigned Medisafe app open",
                                 "srcSet": `${medisafeThumbnail451} 451w, ${medisafeThumbnail735} 735w, ${medisafeThumbnail1200} 1200w`,
                                 "width": '735',
                                 "height": '488'
                             }}
                             ariaLabel="View the Medisafe case study"
                             visible={filter == null ? true : filter["Medisafe"]}
                             minify={minify}
                />

                <ProjectItem title="Negative Carbon Reforestation Project"
                             caption="Scaling reforestation efforts with an intuitive AI-powered platform."
                             url="/project/negative-carbon-reforestation"
                             thumbnailProperties={{
                                 "src": ncrpThumbnail451,
                                 "alt": "Forester holdiong a laptop with a Negative Carbon Reforestation Project demo",
                                 "srcSet": `${ncrpThumbnail451} 451w, ${ncrpThumbnail735} 735w, ${ncrpThumbnail1200} 1200w`,
                                 "width": '735',
                                 "height": '488'
                             }}
                             ariaLabel="View the Negative Carbon Reforestation Project case study"
                             visible={filter == null ? true : filter["Negative Carbon Reforestation"]}
                             minify={minify}
                />

                <ProjectItem title="Archaeophyte"
                             caption="Crafting an immersive experience for a sci-fi side-scrolling adventure."
                             url="/project/archaeophyte"
                             thumbnailProperties={{
                                 "src": archaeophyteThumbnail451,
                                 "alt": "Friends playing Archaeophyte on their NES console",
                                 "srcSet": `${archaeophyteThumbnail451} 451w, ${archaeophyteThumbnail735} 735w, ${archaeophyteThumbnail1200} 1200w`,
                                 "width": '735',
                                 "height": '488'
                             }}
                             ariaLabel="View the Archaeophyte case study"
                             visible={filter == null ? true : filter["Archaeophyte"]}
                             minify={minify}
                />
        </section>
    );
};

export default ProjectCollection;
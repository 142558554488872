import React, {useRef} from "react";
import {Helmet} from "react-helmet";
import Navigation from "../base/Navigation";
import styles from "../../styles/components/pages/Contact.module.css";
import Footer from "../base/Footer";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import closeIcon from "../../resources/images/icons/Close-White.svg";

const Contact = () => {
    const contactFormRef = useRef(null);
    const contactToastRef = useRef(null);
    const contactToastAlertRef = useRef(null);

    const nameInputRef = useRef(null);
    const nameInputErrorRef = useRef(null);

    const emailInputRef = useRef(null);
    const emailInputErrorRef = useRef(null);

    const messageInputRef = useRef(null);
    const messageInputErrorRef = useRef(null);

    const recaptchaInputRef = useRef(null);
    const recaptchaInputErrorRef = useRef(null);

    const sanitizeInput = (value) => {
        let div = document.createElement("div");
        div.textContent = value;
        return div.innerHTML;
    }

    const isValidName = () => {
        let nameInput = nameInputRef.current;
        let nameValue = sanitizeInput(nameInput.value);

        if (nameValue.length >= 2)
        {
            nameInputErrorRef.current.style.visibility = "hidden";
            nameInputRef.current.style.borderColor = "var(--color-dark-white)";
            return true;
        }
        else
        {
            nameInputErrorRef.current.style.visibility = "visible";
            nameInput.style.borderColor = "var(--color-dark-red)";
            return false;
        }
    }

    const isValidEmailPattern = (value) => {
        let emailPattern = /[\w\.-]+@[\w\.-]+\.\w+$/;
        return emailPattern.test(value);
    }

    const isValidEmail = () => {
        let emailInput = emailInputRef.current;
        let emailValue = sanitizeInput(emailInput.value);

        if (isValidEmailPattern(emailValue))
        {
            emailInputErrorRef.current.style.visibility = "hidden";
            emailInputRef.current.style.borderColor = "var(--color-dark-white)";
            return true;
        }
        else
        {
            emailInputErrorRef.current.style.visibility = "visible";
            emailInput.style.borderColor = "var(--color-dark-red)";
            return false;
        }
    }

    const isValidMessage = () => {
        let messageInput = messageInputRef.current;
        let messageValue = sanitizeInput(messageInput.value);

        if (messageValue.length >= 2)
        {
            messageInputErrorRef.current.style.visibility = "hidden";
            messageInputRef.current.style.borderColor = "var(--color-dark-white)";
            return true;
        }
        else
        {
            messageInputErrorRef.current.style.visibility = "visible";
            messageInput.style.borderColor = "var(--color-dark-red)";
            return false;
        }
    }

    const isValidRecaptcha = () => {
        let recaptchaValue = recaptchaInputRef.current.getValue();

        if (recaptchaValue !== "")
        {
            recaptchaInputErrorRef.current.style.visibility = "hidden";
            return true;
        }
        else
        {
            recaptchaInputErrorRef.current.style.visibility = "visible";
            return false;
        }
    }

    const toastMessage = (message, borderColor="var(--color-dark-green)", toastTimeout=2000) => {
        contactToastAlertRef.current.textContent = message
        contactToastRef.current.style.visibility = "visible";
        contactToastRef.current.style.borderLeftColor = borderColor;

        setTimeout(() => contactToastRef.current.style.visibility = "hidden", toastTimeout);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        let validName = isValidName();
        let validEmail = isValidEmail();
        let validMessage = isValidMessage();
        let validRecaptcha = isValidRecaptcha();

        let isValidEntry = validName &&
                           validEmail &&
                           validMessage &&
                           validRecaptcha;

        if (isValidEntry)
        {
            sendEmail();
            handleFormReset();
        }
    }

    const handleFormReset = () => {
        nameInputErrorRef.current.style.visibility = "hidden";
        nameInputRef.current.style.borderColor = "var(--color-dark-white)";

        emailInputErrorRef.current.style.visibility = "hidden";
        emailInputRef.current.style.borderColor = "var(--color-dark-white)";

        messageInputErrorRef.current.style.visibility = "hidden";
        messageInputRef.current.style.borderColor = "var(--color-dark-white)";

        recaptchaInputErrorRef.current.style.visibility = "hidden";
        recaptchaInputRef.current.reset();

        contactFormRef.current.reset();
    }

    const sendEmail = () => {
        let templateParams = {
            from_name: nameInputRef.current.value,
            to_name: "Asel Sathkumara",
            message: messageInputRef.current.value,
            user_email: emailInputRef.current.value

        }

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_KEY)
            .then((response) =>  {
                toastMessage("Message sent!");
            }, (error) => {
                toastMessage("Message not sent.", "var(--color-dark-red)");
            });
    }



    return (
        <>
            <Helmet>
                <title>Contact - Asel's Product Design Portfolio</title>
                <meta
                    name="description"
                    content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"
                />

                {/*Open Graph*/}
                <meta property="og:title" content="Asel | Product Designer" />
                <meta property="og:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!" />
                <meta property="og:url" content="https://asathkumara.com/" />
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:alt" content="A thumbnail featuring my signature" />

                {/*Twitter*/}
                <meta name="twitter:title" content="Asel | Product Designer"/>
                <meta name="twitter:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"/>
                <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta name="twitter:image:alt" content="A thumbnail featuring my signature"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <Navigation/>
            <main>
                <section id={styles.hero}>
                    <div className="container">
                        <p id={styles.caption}>Contact</p>
                        <h1 id={styles.hero_header}>Let's start a <span className="highlight_purple">conversation</span>.</h1>
                    </div>
                </section>

                <section id="content">
                        <section id={styles.about}>
                            <div id={styles.content_container} className="container">
                                <section id={styles.contact_instructions}>
                                    <p>Whether you have a question, want to discuss a project, or simply say hello, I'd love to hear from you! Your thoughts, ideas, and feedback are important to me. Let's start a conversation and explore the possibilities together!</p>
                                    <h2>Send me a note below, or <a id={styles.schedule_call_link} className="underscored_visible" href="https://calendly.com/asathkumara/30min" rel="noreferrer noopener nofollow" target="_blank" aria-label="Schedule a 30 minute call with me on Calendly.">schedule a 30-minute call.</a></h2>

                                    <form ref={contactFormRef}
                                          id={styles.contact_form}
                                          action=""
                                          aria-label="Send me a note below"
                                          onSubmit={(event) => handleFormSubmit(event)}>
                                        <div className={styles.contact_form_input_container}>
                                            <label htmlFor="name">Name (required)</label>
                                            <input ref={nameInputRef}
                                                   id="name"
                                                   type="text"
                                                   name="name"
                                                   minLength={2}
                                                   title="Enter a name that is longer than 2 characters."
                                                   // required={true}
                                                   autoComplete="name"
                                                   aria-describedby="name-error"
                                                   onBlur={(event) => isValidName()}
                                            />
                                            <p ref={nameInputErrorRef} id="name-error">&#9432; &nbsp;Enter a name that is longer than 2 characters.</p>
                                        </div>

                                        <div className={styles.contact_form_input_container}>
                                            <label htmlFor="email">Email (required)</label>
                                            <input ref={emailInputRef}
                                                   id="email"
                                                   type="text"
                                                   name="email"
                                                   title="Enter an email in the format of name@example.com."
                                                   autoComplete="email"
                                                   aria-describedby="email-error"
                                                   onBlur={(event) => isValidEmail()}
                                            />
                                            <p ref={emailInputErrorRef} id="email-error">&#9432; &nbsp;Enter an email in the format of name@example.com.</p>
                                        </div>

                                        <div className={styles.contact_form_input_container}>
                                            <label htmlFor="message">Message (required)</label>
                                            <textarea ref={messageInputRef}
                                                      id="message"
                                                      type="text"
                                                      name="name"
                                                      minLength={2}
                                                      title="Enter a message that is longer than 2 characters."
                                                      aria-describedby="message-error"
                                                      onBlur={(event) => isValidMessage()}
                                            />
                                            <p ref={messageInputErrorRef} id="message-error">&#9432; &nbsp;Enter a message that is longer than 2 characters.</p>
                                        </div>

                                        <div className={styles.contact_form_input_container}>
                                            <label htmlFor="message">Recaptcha (required)</label>
                                            <ReCAPTCHA ref={recaptchaInputRef}
                                                       sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                       theme="dark"
                                                       size="normal"
                                            />
                                            <p ref={recaptchaInputErrorRef}>&#9432; &nbsp;Complete the reCaptcha challenge.</p>
                                        </div>

                                        <button id={styles.contact_form_submit}
                                                type="submit"
                                                className="button_ghost"
                                        >
                                            Send Message
                                        </button>
                                    </form>

                                    <aside ref={contactToastRef} id={styles.toast} role="alert">
                                        <p ref={contactToastAlertRef} id={styles.toast_alert} aria-live="polite">Message Sent!</p>
                                        <button id={styles.toast_close}
                                                aria-label="Close toast."
                                                onClick={() => contactToastRef.current.style.visibility = "hidden"}>
                                            <img  src={closeIcon} alt="Menu Icon"/>
                                        </button>
                                    </aside>
                                </section>
                            </div>
                        </section>
                </section>
            </main>

            <Footer/>
        </>
    )
}

export default Contact;
import React from "react";
import {Helmet} from "react-helmet";
import Navigation from "../base/Navigation";
import styles from "../../styles/components/pages/Services.module.css";
import Footer from "../base/Footer";
import ProjectCollection from "../utils/ProjectCollection";
import ServiceCollection from "../utils/ServiceCollection";

const Services = () => {

    return (
        <>
            <Helmet>
                <title>Services - Asel's Product Design Portfolio</title>
                <meta
                    name="description"
                    content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"
                />

                {/*Open Graph*/}
                <meta property="og:title" content="Asel | Product Designer" />
                <meta property="og:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!" />
                <meta property="og:url" content="https://asathkumara.com/" />
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:alt" content="A thumbnail featuring my signature" />

                {/*Twitter*/}
                <meta name="twitter:title" content="Asel | Product Designer"/>
                <meta name="twitter:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"/>
                <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta name="twitter:image:alt" content="A thumbnail featuring my signature"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <Navigation/>
            <main>
                <section id={styles.hero}>
                    <div id={styles.hero_container} className="container">
                        <p id={styles.caption}>Services</p>
                        <h1 id={styles.hero_header}>Data-driven.<br/> <span className="highlight_purple">User-focused</span>.</h1>
                    </div>
                </section>

                <section id={styles.content}>
                   <section id={styles.services}>
                       <div className="container">
                         <h2 id={styles.services_header}>I help brands elevate their user experiences with individualized attention, ensuring a user-centric approach that sets them apart from the crowd.</h2>
                        <ServiceCollection/>
                       </div>
                    </section>
                </section>
            </main>

            <Footer/>
        </>
    )
}

export default Services;
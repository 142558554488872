import React from "react";
import {Helmet} from "react-helmet";
import Navigation from "../base/Navigation";
import styles from "../../styles/components/pages/NotFound.module.css";
import Footer from "../base/Footer";
import ProjectCollection from "../utils/ProjectCollection";


const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>Page Not Found - Asel's Product Design Portfolio</title>
                <meta
                    name="description"
                    content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"
                />

                {/*Open Graph*/}
                <meta property="og:title" content="Asel | Product Designer" />
                <meta property="og:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!" />
                <meta property="og:url" content="https://asathkumara.com/" />
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:alt" content="A thumbnail featuring my signature" />

                {/*Twitter*/}
                <meta name="twitter:title" content="Asel | Product Designer"/>
                <meta name="twitter:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"/>
                <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta name="twitter:image:alt" content="A thumbnail featuring my signature"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <Navigation/>

            <main id={styles.main}>
                <section id={styles.hero}>
                    <div className="container">
                        <section>
                            <p id={styles.caption}>404 Error</p>
                            <h1 id={styles.hero_header}>The page you&rsquo;re looking for <span className="highlight_purple">can&rsquo;t be found.</span></h1>

                            <section id={styles.hero_links}>
                                <a className="underscored_visible " href="/" aria-label="Go To Homepage">Go To Homepage &#8640;</a>
                                <a className="underscored_visible" href="mailto:asathkumara.dev@gmail.com" aria-label="Contact me">Contact Me &#8640;</a>
                            </section>

                        </section>
                    </div>
                </section>

                <section id={styles.content}>
                    <section id={styles.projects}>
                        <div className="container">
                            <section id={styles.projects_header}>
                                <h2>Featured Projects</h2>
                                <a className="underscored_visible" href="/projects">All Work &#8640;</a>
                            </section>
                            <ProjectCollection/>
                        </div>
                    </section>
                </section>
            </main>

            <Footer/>
        </>
    )
}

export default NotFound;
import React, {useEffect} from "react";
import Navigation from "../base/Navigation";
import styles from "../../styles/components/pages/Index.module.css";
import Footer from "../base/Footer";
import {Helmet} from "react-helmet";
import ProjectCollection from "../utils/ProjectCollection";
import ServiceCollection from "../utils/ServiceCollection";
import {generateClasses} from "../../scripts/Helpers";

const Index = () => {
    return (
        <>
            <Helmet>
                <title>Home - Asel's Product Design Portfolio</title>
                <meta
                    name="description"
                    content="A Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"
                />

                {/*Open Graph*/}
                <meta property="og:title" content="Asel | Product Designer" />
                <meta property="og:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!" />
                <meta property="og:url" content="https://asathkumara.com/" />
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:alt" content="A thumbnail featuring my signature" />

                {/*Twitter*/}
                <meta name="twitter:title" content="Asel | Product Designer"/>
                <meta name="twitter:description" content="Asel is a Product Designer that is passionate about helping businesses ideate solutions to complex user problems. Check out his portfolio and get in touch!"/>
                <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/social-thumbnail-800x450.png`}/>
                <meta name="twitter:image:alt" content="A thumbnail featuring my signature"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <Navigation/>
            <main id={styles.main}>
                <section id={styles.hero}>
                    <div id={styles.hero_container} className="container">
                        <h1 id={styles.hero_caption}>Hello, I'm Asel —</h1>
                        <h2 id={styles.hero_subcaption}>A <span className="highlight_purple">Product Designer</span> specializing in crafting intuitive and accessible experiences.</h2>

                        <div id={styles.figma_cursor}>
                            <svg width="23" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#a)">
                                    <path d="m7.5 17.5-2-12 10 6L10 13l-2.5 4.5Z" fill="#894E8A"></path>
                                    <path
                                        d="m5.757 5.071-.928-.557.178 1.068 2 12 .235 1.412.695-1.251 2.398-4.316 5.297-1.445 1.13-.308-1.005-.603-10-6Z"
                                        stroke="#fff"></path>
                                </g>
                                <defs>
                                    <filter id="a" x=".158" y=".528" width="21.866" height="24.959"
                                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha"
                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                       result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="1"></feOffset>
                                        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                                        <feComposite in2="hardAlpha" operator="out"></feComposite>
                                        <feColorMatrix
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
                                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_4"></feBlend>
                                        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1_4"
                                                 result="shape"></feBlend>
                                    </filter>
                                </defs>
                            </svg>
                            <span id={styles.figma_cursor_name}>Asel</span>
                        </div>
                    </div>
                </section>

                <section id={styles.content}>
                    <div className="container">
                        <section id={styles.projects}>
                            <section id={styles.projects_header}>
                                <h2>Featured Projects</h2>
                                <a className="underscored_visible" href="/projects">All Work ⇀</a>
                            </section>
                            <ProjectCollection/>
                        </section>

                        <section id={styles.services}>
                            <section id={styles.projects_header}>
                                <h2>Featured Services</h2>
                                <a className="underscored_visible" href="/services">All Services ⇀</a>
                            </section>
                            <ServiceCollection/>
                        </section>
                    </div>
                </section>
            </main>
            <Footer/>

        </>

    )
}

export default Index;
import React from "react";
import {serviceMap} from "../../scripts/Helpers";
import {useParams} from "react-router";
import NotFound from "./NotFound";

const Service = () => {

    const { serviceID } = useParams();

    /**
     * Render a service based on the route param.
     * @returns {JSX.Element|*} The service component if the route is valid; Otherwise, returns NotFound.
     */
    const renderService = () => {
        if (serviceMap[serviceID])
        {
            return serviceMap[serviceID].component;
        }
        return <NotFound/>;
    }

    return (
        <>
            {renderService()}
        </>
    )
}

export default Service;
import React from "react";
import {projectMap} from "../../scripts/Helpers";
import {useParams} from "react-router";
import NotFound from "./NotFound";

const Project = () => {

    const { projectID } = useParams();

    /**
     * Render a project based on the route param.
     * @returns {JSX.Element|*} The project component if the route is valid; Otherwise, returns NotFound.
     */
    const renderProject = () => {
        if (projectMap[projectID])
        {
            return projectMap[projectID].component;
        }
        return <NotFound/>;
    }

    return (
        <>
            {renderProject()}
        </>
    )
}

export default Project;
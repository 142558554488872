import React, {useEffect, useRef} from "react";
import styles from "../../styles/components/base/Navigation.module.css";

import hamburgerWhite from "../../resources/images/icons/Hamburger-White.svg";
import closeWhite from "../../resources/images/icons/Close-White.svg";

const Navigation = ({className, onScrollTitle, showScrollIndicator=false, skipContentSelector = "#content", skipFooterSelector="footer"}) => {
    const headerRef = useRef(null);
    const scrollTitleRef = useRef(null);
    const headerTitleRef = useRef(null);
    const scrollIndicatorRef = useRef(null);

    const positionRefForBackgroundChange = useRef(0);
    const positionRefForTitleChange = useRef(0);

    const mainNavRef = useRef(null);
    const mobileNavRef = useRef(null);
    const menuButtonImgRef = useRef(null);

    /**
     * Handles changing the background of the navigation
     *
     * @remarks The navigation background is initially transparent and becomes solid once scrolled.
     */
    const handleBackground = () => {
        let lastPosition = positionRefForBackgroundChange.current;
        let currentPosition = window.pageYOffset || document.documentElement.scrollTop;
        let isScrollingDown = currentPosition > lastPosition;

        if (isScrollingDown)
        {
            headerRef.current.style.background = "var(--background-primary)";
            headerRef.current.style.borderBottom ="2px solid var(--color-dark-white)";
        }

        if (currentPosition <= 10)
        {
            headerRef.current.style.background = "transparent";
            headerRef.current.style.borderBottom ="0";
        }

        positionRefForBackgroundChange.current = currentPosition;;


    }

    /**
     * Handles the scroll indicator
     * @remarks Case studies can enable this effect by passing in an showScrollIndicator parameter. By default, it's set to false.
     */
    const handleScrollIndicator = () => {
        if (!showScrollIndicator)
        {
            return;
        }

        let currentPosition = document.body.scrollTop || document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let scrolled = (currentPosition / height) * 100;

        scrollIndicatorRef.current.style.width =   `${scrolled}%`;
    }

    /**
     * Handles changing the title on the navigation
     * @remarks Case studies can enable this effect by passing in an onScrollTitle parameter.
     */
    const handleTitleChange = () => {

        if (onScrollTitle === undefined || onScrollTitle === "")
        {
            return;
        }

        let lastPosition = positionRefForTitleChange.current;
        let currentPosition = window.pageYOffset || document.documentElement.scrollTop;
        let isScrollingDown = currentPosition > lastPosition;;


        if (isScrollingDown)
        {
            scrollTitleRef.current.style.display = "block";
            headerTitleRef.current.style.display = "none";
        }
        else
        {
            scrollTitleRef.current.style.display = "none";
            headerTitleRef.current.style.display = "block";
        }

        positionRefForTitleChange.current = window.pageYOffset;

    }

    /**
     * Handles toggling the mobile navigation on large screens
     * @param event The event object
     */
    const handleMobileNavOnLargeScreens = (event) => {
        if (!event.matches)
        {
            mobileNavRef.current.style.display = "none";
            menuButtonImgRef.current.src = hamburgerWhite;
            menuButtonImgRef.current.style.transform = "rotate(0deg)";
        }
    }

    /**
     * Subscribes functions to the scroll event on the window
     */
    const subscribeToScroll = () => {
        window.addEventListener("scroll", handleBackground);
        window.addEventListener("scroll", handleTitleChange);
        window.addEventListener("scroll", handleScrollIndicator);
    }

    /**
     * Unsubscribes functions to the scroll event on the window
     */
    const unsubscribeToScroll = () => {
        window.removeEventListener("scroll", handleBackground);
        window.removeEventListener("scroll", handleTitleChange);
        window.removeEventListener("scroll", handleScrollIndicator);
    }

    /**
     * Subscribes functions to the media change event on the window
     */
    const subscribeToMediaChange = () => {
        window.matchMedia("screen and (max-width: 60em)")
              .addEventListener("change", (event) => handleMobileNavOnLargeScreens(event))
    }

    /**
     * Unsubscribes functions to the media change event on the window
     */
    const unsubscribeToMediaChange = () => {
        window.matchMedia("screen and (max-width: 60em)")
            .removeEventListener("change", (event) => handleMobileNavOnLargeScreens(event))
    }

    /**
     * Handles the click event on the menu button
     *
     * @remarks When the menu button is clicked, the mobile menu is toggled on and off.
     * The icon changes to a hamburger / close depending on whether it's toggled, and there's a animated rotation of 90degs.
     */
    const handleMenuClick = () => {
        if (mobileNavRef.current.style.display !== "block")
        {
            scrollIndicatorRef.current.style.display = "none";

            mobileNavRef.current.style.display = "block";
            headerRef.current.style.background = "var(--background-primary)";
            headerRef.current.style.borderBottom ="2px solid var(--color-dark-white)";

            document.body.style.overflowY = "hidden";

            menuButtonImgRef.current.src = closeWhite;
            menuButtonImgRef.current.style.transform = "rotate(90deg)";
        }
        else
        {
            mobileNavRef.current.style.display = "none";
            document.body.style.overflowY = "visible";
            headerRef.current.style.background = window.pageYOffset <= 10 ? "transparent" : "var(--background-primary)";
            scrollIndicatorRef.current.style.display = "block";

            menuButtonImgRef.current.src = hamburgerWhite;
            menuButtonImgRef.current.style.transform = "rotate(0deg)";
        }
    }

    /**
     * Handles the click event on skip links with the given selector
     * @param skipLinkSelector The selector for the skip link
     * @remarks If the skip link element is not focusable, set the tabIndex to -1 and focus it.
     * -1 makes the element focusable but hides it from the natural tab order.
     */
    const handleSkipLink = (skipLinkSelector) => {
        let contentElement = document.querySelector(skipLinkSelector);

        if (contentElement)
        {
            contentElement.tabIndex = -1;
            contentElement.focus();
        }
    }

    /**
     * On component mount, subscribe functions to the window scroll and media change events.
     * Unsubscribe on dismount.
     */
    useEffect(() => {
        subscribeToScroll();
        subscribeToMediaChange();

        return () => {
            unsubscribeToScroll();
            unsubscribeToMediaChange();
        }
    }, [])

    return (
        <>
            <button id={styles.skipLink}
                    className="button_primary"
                    aria-label="Skip to content."
                    onClick={() => handleSkipLink(skipContentSelector)}
            >
                Skip to content
            </button>

            <button id={styles.skipLink}
                    className="button_primary"
                    aria-label="Skip to footer."
                    onClick={() => handleSkipLink(skipFooterSelector)}
            >
                Skip to footer
            </button>

            <header ref={headerRef} id={styles.header} role="banner">
                <div id={styles.header_container} className="container">
                    <a ref={headerTitleRef} className="underscored" href="/" aria-label="Go back to the home page." rel="nofollow">Asel Sathkumara</a>
                    <div ref={scrollTitleRef} id={styles.scroll_title}>{onScrollTitle}</div>

                    <button id={styles.menu_button}
                            onClick={handleMenuClick}
                            aria-label="Toggle Navigation Menu"
                            aria-haspopup="menu"
                            aria-expanded="false"
                    >
                        <img ref={menuButtonImgRef} src={hamburgerWhite} alt="Menu Icon"/>
                    </button>

                    <nav id={styles.main_nav} ref={mainNavRef} role="navigation">
                        <ul id={styles.nav_items}>
                            <li id={styles.nav_item_projects}>
                                <a className="underscored" href="/services" aria-label="View my services">Services</a>
                            </li>

                            <li id={styles.nav_item_projects}>
                                <a className="underscored" href="/projects" aria-label="View my work">Work</a>
                            </li>

                            <li id={styles.nav_item_about}>
                                <a className="underscored" href="/about" aria-label="Learn more about me">About</a>
                            </li>

                            <li id={styles.nav_item_contact}>
                                <a id={styles.nav_item_contact_link} className="" href="/contact" aria-label="Contact me at my email address">Contact</a>
                            </li>
                        </ul>
                    </nav>

                    <nav id={styles.mobile_nav} ref={mobileNavRef} role="navigation">
                        <div className="container">
                            <ul id={styles.nav_items}>
                                <li id={styles.nav_item_projects}>
                                    <a className="underscored" href="/services" aria-label="View my services">Services</a>
                                </li>

                                <li id={styles.nav_item_projects}>
                                    <a className="underscored" href="/projects" aria-label="View my work">Work</a>
                                </li>

                                <li id={styles.nav_item_about}>
                                    <a className="underscored" href="/about" aria-label="Learn more about me">About</a>
                                </li>

                                <li id={styles.nav_item_contact}>
                                    <a id="contact" className="underscored" href="/contact" aria-label="Contact me at my email address">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div ref={scrollIndicatorRef} id={styles.scroll_indicator} aria-hidden="true"></div>
            </header>
        </>
    )
}

export default Navigation;
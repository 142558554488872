import React, {useRef} from "react";
import {generateClasses} from "../../scripts/Helpers";
import styles from "../../styles/components/utils/ServiceItem.module.css";

const ServiceItem = ({classNames, title, caption, url, ariaLabel, visible=true}) => {

    const serviceRef = useRef();

    /**
     * Renders a service depending on whether it should be visible
     * @returns {JSX.Element|null} The service if visible; or null.
     */
    const renderService = () => {
        if (visible)
        {
            return <a ref={serviceRef}
                      href={url}
                      target="_self"
                      className={generateClasses(classNames, styles.service_item)}
                      aria-label={ariaLabel}
            >
                <div className={styles.service_item_content}>
                    <div className={styles.service_title}>{title}</div>
                    <p>{caption}</p>
                </div>
            </a>
        }

        return null;
    }

    return (
        renderService()
    )
}

export default ServiceItem;
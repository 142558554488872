import {lazy} from "react";

const scrollIntoViewDefault = {
    behavior: "smooth"
}

const generateClasses = (...classes) => {
    return classes.join(" ")
}

const isElementInViewport = (element) => {
    let rect = element.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

const scrollIntoView = (elementID, pathName, scrollIntoViewOptions=scrollIntoViewDefault) => {
    redirectIfNeeded(pathName);

    document.getElementById(elementID).scrollIntoView(scrollIntoViewOptions);
}

const redirectIfNeeded = (pathName) => {
    if (window.location.pathname !== pathName)
    {
        window.location.href =`${window.location.origin}${pathName}`;
    }
}

const NCRP = lazy(() => import("../components/pages/projects/NCRP"));
const Medisafe = lazy(() => import("../components/pages/projects/Medisafe"));
const Archaeophyte = lazy(() => import("../components/pages/projects/Archaeophyte"));
const SearchEngineOptimization = lazy(() => import("../components/pages/services/SearchEngineOptimization"));
const WebDesignAndDevelopment = lazy(() => import("../components/pages/services/WebDesignAndDevelopment"));
const HeuristicEvaluation = lazy(() => import("../components/pages/services/HeuristicEvaluation"));
const AccessibilityAuditing = lazy(() => import("../components/pages/services/AccessibilityAuditing"));

const projectMap = {
    "negative-carbon-reforestation": {
        name: "Negative Carbon Reforestation",
        component: <NCRP/>,
        url: "/project/negative-carbon-reforestation"
    },
    "medisafe": {
        name: "Medisafe",
        component: <Medisafe/>,
        url: "/project/medisafe"
    },
    "archaeophyte": {
        name: "Archaeophyte",
        component: <Archaeophyte/>,
        url: "/project/archaeophyte"
    }
}

const serviceMap = {
    "web-design-and-development": {
        name: "Website Design & Development",
        component: <WebDesignAndDevelopment/>,
        url: "/services/web-design-and-development"
    },
    "accessibility-auditing": {
        name: "Accessibility Auditing",
        component: <AccessibilityAuditing/>,
        url: "/services/accessibility-auditing"
    },
    "heuristic-evaluation": {
        name: "Heuristic Evaluation",
        component: <HeuristicEvaluation/>,
        url: "/services/heuristic-evaluation"
    },
    "search-engine-optimization": {
        name: "Search Engine Optimization",
        component: <SearchEngineOptimization/>,
        url: "/services/search-engine-optimization"
    }
}

export
{
    generateClasses,
    scrollIntoView,
    isElementInViewport,
    redirectIfNeeded,
    projectMap,
    serviceMap
}
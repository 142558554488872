import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route} from "react-router-dom";
import {Routes} from "react-router";
import Index from "./components/pages/Index";
import Work from "./components/pages/Work";
import About from "./components/pages/About";
import Project from "./components/pages/Project";
import NotFound from "./components/pages/NotFound";
import TagManager from "react-gtm-module/dist/TagManager";
import Services from "./components/pages/Services";
import Service from "./components/pages/Service";
import Contact from "./components/pages/Contact";

// Note to self: Testing GTM is broken on Google Chrome due to some extension. Use Edge.
TagManager.initialize({
    gtmId: "GTM-MJFGR3H",
    auth: "hOmKQK_V-mj9CneWUdANGQ",
    preview: "env-1"
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Index/>}/>
            <Route path="/services" element={<Services/>}/>
                <Route path="/services/:serviceID" element={<Service/>}/>
            <Route path="/projects" element={<Work/>}/>
                <Route path="/project/:projectID" element={<Project/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
